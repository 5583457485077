import React from 'react';
import cx from 'classnames';
import { useModal } from 'react-aria';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmationDialog } from 'components/design-system/Dialog/hooks';
import { Button } from 'components/design-system/Button/Button';
import { Icon } from 'components/design-system/Icon/Icon';
import { Switch } from 'components/design-system/Switch/Switch';
import { ChevronDownIcon, ChevronUpIcon } from 'components/ui/svg';
import css from 'components/features/BradControl/BradControl.module.css';
import settingsCss from 'scenes/Main/Settings/Settings.module.css';

import { BodySemiBold, Header, ListBodySmall } from 'components/ui/Typography';
import { EdenColors } from 'types/colors';
import { BodyNormal, HeaderSmall } from 'components/design-system/Text';
import { getBradStatus, setBradActive, setBradInactive } from 'reducers/brad';
import { BradState } from 'types/grdn';

const statusDisplay: Record<BradState, string> = {
  [BradState.Active]: 'Active',
  [BradState.Inactive]: 'Inactive',
};

const Toggle = () => {
  const dispatch = useDispatch();
  const currentStatus = useSelector(getBradStatus);

  const { modalProps: archiveConfirmationModalProps } = useModal();
  const { open: openConfirmationDialog, ConfirmationDialog } = useConfirmationDialog({
    title: 'Turn BRAD off?',
    iconName: 'bell-filled',
    description:
      'This will deactivate BRAD banners in the patient app, and stop all on-call pages.',
    actionType: 'success',
    confirmLabel: 'Turn BRAD off',
    onConfirm: () => dispatch(setBradInactive()),
    modalProps: archiveConfirmationModalProps,
  });

  const changeStatusHandlers: Record<BradState, () => void> = {
    [BradState.Active]: openConfirmationDialog,
    [BradState.Inactive]: () => dispatch(setBradActive()),
  };

  return (
    <div className={css.switchContainer}>
      <ListBodySmall>{statusDisplay[currentStatus]}</ListBodySmall>
      <Switch
        label="Active"
        isSelected={currentStatus === BradState.Active}
        onChange={() => {
          changeStatusHandlers[currentStatus]();
        }}
      />
      <ConfirmationDialog />
    </div>
  );
};

export const BradControl = () => {
  return (
    <div data-testid={'brad-control-page'}>
      <div className={settingsCss.column}>
        <Header className={css.header}>BRAD Control</Header>
        <BodyNormal>
          <div className={cx(css.statusContainer, css.bottomSeparator)}>
            <HeaderSmall>BRAD status is:</HeaderSmall>
            <Toggle />
          </div>
          <HeaderSmall>About</HeaderSmall>
          <p>BRAD stands for Banner Real-time Alert Dispatcher.</p>
          <p>This control indicates whether or not BRAD is currently active.</p>
          <p>When BRAD is active:</p>
          <ul className={css.list}>
            <li>It needs to be manually turned off</li>
            <li>A provider must be scheduled in PagerDuty and on call</li>
            <li>Patients see this banner in the chat view in the app</li>
          </ul>
        </BodyNormal>
      </div>
      <div className={css.row}>
        <div>
          <div className={css.label}>
            <BodyNormal>Minimized modal</BodyNormal>
          </div>

          <div className={css.modal}>
            <HeaderSmall className={css.modalHeader}>
              <Icon name="bell-filled" />
              Need care now?
              <ChevronDownIcon color={'currentColor' as EdenColors} />
            </HeaderSmall>
          </div>
        </div>
        <div>
          <div className={css.label}>
            <BodyNormal>Maximized modal</BodyNormal>
          </div>

          <div className={cx(css.modal, css.expanded)}>
            <HeaderSmall className={css.modalHeader}>
              <Icon name="bell-filled" className={css.magnified} />
              <ChevronUpIcon color={'currentColor' as EdenColors} />
            </HeaderSmall>

            <BodySemiBold>You&apos;ve reached us outside of bussiness hours.</BodySemiBold>

            <BodySemiBold>
              We&apos;re not immediately available to chat right now, but look forward to caring for
              you soon. You can expect to hear from a member of our team during the next business
              day.
            </BodySemiBold>

            <hr />

            <HeaderSmall>For after-hours support, call the nurse line to get started</HeaderSmall>

            <Button
              type="primary"
              actionType="info"
              onPress={() => {
                // Do nothing.
              }}
            >
              (407) 205-1674
            </Button>

            <BodyNormal className={css.emergencyCallout}>
              If this is an emergency, please dial 911 or go to your nearest emergency room. If you
              are in need of immediate mental health support, please call or text 988.
            </BodyNormal>
          </div>
        </div>
      </div>
    </div>
  );
};
